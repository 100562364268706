'use strict';

angular.module('kljDigitalLibraryApp')
    .service('savedTabsService', ["$q", "common", "appConfig",
        function($q, common, appConfig) {

            var baseUrl = '/api/users';

            this.saveTabs = function(savedTabs) {

                var url = '/api/users/save/searchInfo';

                var deferred = $q.defer();

                common.callApi('POST',
                    url,
                    appConfig.EMPTY_STRING,
                    appConfig.CONTENT_TYPE, {
                        "key": "saved_tabs",
                        "data": savedTabs
                    }
                )

                .then(function(cases) {
                        deferred.resolve(cases);
                    })
                    .catch(function(error) {

                        deferred.reject(error);
                    });

                return deferred.promise;

            };

            this.getData = function(payload) {


                return common.callApi('POST',
                    baseUrl + '/get/saveTabList',
                    appConfig.EMPTY_STRING,
                    appConfig.CONTENT_TYPE,
                    payload
                )

                .then(function(response) {

                    return (response.data);
                });

            }

            this.removeItem = function(id) {

                var deferred = $q.defer();

                common.callApi('DELETE',
                    baseUrl + '/delete/savedTab/' + id,
                    appConfig.EMPTY_STRING,
                    appConfig.CONTENT_TYPE
                )

                .then(function(response) {
                    deferred.resolve(response);
                })

                .catch(function(error) {

                    deferred.reject(error);
                });

                return deferred.promise;
            }
        }
    ]);